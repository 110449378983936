import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

import TextField from "components/element/form/TextField";
import LoginMessage from "./LoginMessage";

import styles from "./loginForm.local.css";

const cx = classNames.bind(styles);

class LoginForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleLogin: PropTypes.func.isRequired,
    handleUsername: PropTypes.func.isRequired,
    handlePassword: PropTypes.func.isRequired,
    isLoggingIn: PropTypes.bool,
    message: PropTypes.string
  };

  handleKeyPress = e => {
    const { handleLogin } = this.props;

    if (e.key === "Enter") {
      handleLogin();
    }
  };

  render() {
    const {
      error,
      handleLogin,
      handleUsername,
      handlePassword,
      isLoggingIn,
      message
    } = this.props;

    return (
      <div className="form-group">
        {message !== null && (
          <div className={`alert alert-success ${cx("infobox")}`}>
            {message}
          </div>
        )}
        <div className={cx("introtext")}>Bei TrackCode anmelden</div>
        <div className={cx("textfieldcontainer")}>
          <TextField
            onChange={handleUsername}
            placeholder="Email"
            disabled={isLoggingIn}
            required
            className={cx("input", "name")}
            onKeyPress={this.handleKeyPress}
          />

          <TextField
            type="password"
            onChange={handlePassword}
            placeholder="Passwort"
            disabled={isLoggingIn}
            required
            className={cx("input", "password")}
            onKeyPress={this.handleKeyPress}
          />
          <Link to="/resetPassword" className={cx("forgot-link")}>
            vergessen?
          </Link>
        </div>
        <button
          type="submit"
          className={cx("submit-button", "btn", "btn-success")}
          onClick={handleLogin}
        >
          Anmelden
        </button>
        <div className={cx("infotext")}>
          Durch deine Anmeldung bestätigst du, dass du mit unseren AGBs,
          Datenschutzrichtlinien und der Verwendung von Cookies einverstanden
          bist.
        </div>
        <LoginMessage className={cx("errormessage")} error={error} />
      </div>
    );
  }
}

export default LoginForm;
