import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames/bind";

import FloatingTextField from "../../components/element/form/FloatingTextField";
import UserService from "../../service/UserService";
import Button from "../../components/element/button";
// import Checkbox from "../../components/element/form/Checkbox";
// import Select from "../../components/element/select/Select";

import styleNames from "./CreateEditEmployeeSubForm.local.css";

const cx = classNames.bind(styleNames);

class CreateEditEmployeeSubForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAbort: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    editEmployeeItem: PropTypes.object,
    groups: PropTypes.array,
    tours: PropTypes.array,
    selectedGroup: PropTypes.number
  };

  state = {
    employeeItem: {},
    error: {},
    emailSent: false
  };

  componentWillMount() {
    const { editEmployeeItem } = this.props;
    if (editEmployeeItem) {
      const tempEmployeeItem = editEmployeeItem;
      tempEmployeeItem.password_confirm = tempEmployeeItem.password;
      this.setState({ employeeItem: tempEmployeeItem });
    }
  }

  onChangeTextField = e => {
    e.preventDefault();
    const { name, value, type = "text" } = e.target;
    const { employeeItem } = this.state;

    employeeItem[name] = type === "number" ? Number(value) : value;
    this.setState(() => ({ employeeItem }));
  };

  onReset = () => {
    const { employeeItem } = this.state;

    if (employeeItem.email) {
      this.props.handleReset(employeeItem.email);
      this.setState({ emailSent: true });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const { employeeItem } = this.state;

    const error = {};
    if (!employeeItem.email) {
      error.email = true;
    }

    employeeItem.groups = [this.props.selectedGroup];
    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      const errorSubmit = this.props.handleSubmit(employeeItem);
      if (errorSubmit === "DUPLICATE_EXT_ID") {
        alert("ID ist bereits vergeben.");
      }
    }
  };

  onAbort = () => {
    this.props.handleAbort();
  };

  onDelete = () => {
    const { employeeItem } = this.state;

    const result = window.confirm(
      `Wollen Sie den Nutzer: ${UserService.getName(
        employeeItem
      )} wirklich löschen?`
    );
    if (result) {
      // Logic to delete the item
      this.props.handleDelete(employeeItem);
    }
  };

  onTourSelectionChange = tour => {
    const { employeeItem } = this.state;

    if (tour) {
      employeeItem.tour_id = tour.value;
    }

    this.setState({ employeeItem });
  };

  getTourOptions() {
    const { tours } = this.props;
    const tourOptions = [];
    if (tours && tours.length) {
      for (let i = 0; i < tours.length; i += 1) {
        tourOptions.push({
          value: tours[i].id,
          label: tours[i].label,
          memberId: tours[i].member_id
        });
      }
    }
    return tourOptions;
  }

  groupOnChange = e => {
    const { employeeItem } = this.state;
    const groups = employeeItem.groups || [];
    const val = e.target.value.toString();
    if (e.target.checked) {
      groups.push(val);
    } else {
      groups.splice(groups.indexOf(val), 1);
    }
    employeeItem.groups = groups;
    this.setState({ employeeItem });
  };

  render() {
    const { selectedGroup, ...otherProps } = this.props;

    const { error, employeeItem, emailSent } = this.state;

    const type = selectedGroup === 4 ? "Kurier" : "Disponent";

    return (
      <div className="row" {...otherProps}>
        <div className={cx("col-sm-12", "subform")}>
          <form className={cx("employeeSubForm")} onSubmit={this.onSubmit}>
            <div className={cx("form-group", "titlecontainer")}>
              {!employeeItem.id && (
                <span className={cx("title")}>Neuer {type}</span>
              )}
              {employeeItem.id && (
                <span className={cx("title")}>{type} bearbeiten</span>
              )}
            </div>
            <div className={cx("form-group")}>
              <div className={cx("user-id")}>
                <FloatingTextField
                  type="number"
                  name="ext_id"
                  size="small"
                  floatingLabel="ID (optional)"
                  onChange={this.onChangeTextField}
                  value={employeeItem.ext_id || ""}
                />
              </div>
              <div className={cx("fields")}>
                <FloatingTextField
                  type="text"
                  name="firstname"
                  floatingLabel="Vorname"
                  onChange={this.onChangeTextField}
                  value={employeeItem.firstname || ""}
                />
                <FloatingTextField
                  type="text"
                  name="lastname"
                  floatingLabel="Nachname"
                  onChange={this.onChangeTextField}
                  value={employeeItem.lastname || ""}
                />
                <FloatingTextField
                  type="email"
                  name="email"
                  floatingLabel="Email"
                  onChange={this.onChangeTextField}
                  style={error.email ? "error" : null}
                  value={employeeItem.email || ""}
                />
              </div>
            </div>
            <div
              className={cx("shipment-form-footer-employee", "modal-footer")}
            >
              {employeeItem.id && (
                <React.Fragment>
                  <Button onClick={this.onAbort}>Abbrechen</Button>
                  {/* eslint-disable jsx-a11y/interactive-supports-focus */}
                  <div
                    role="button"
                    className={cx("textlink")}
                    onClick={this.onDelete}
                  >
                    Löschen
                  </div>
                  {/* eslint-enable jsx-a11y/interactive-supports-focus */}
                  {employeeItem.outstandingInvitation && (
                    <Button
                      color="success"
                      onClick={this.onReset}
                      disabled={emailSent}
                    >
                      Einladung erneut senden
                    </Button>
                  )}
                  {!employeeItem.outstandingInvitation && (
                    <Button color="success" onClick={this.onSubmit}>
                      Speichern
                    </Button>
                  )}
                </React.Fragment>
              )}
              {!employeeItem.id && (
                <React.Fragment>
                  <Button onClick={this.onAbort}>Abbrechen</Button>
                  <Button color="success" onClick={this.onSubmit}>
                    Einladung senden
                  </Button>
                </React.Fragment>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateEditEmployeeSubForm;
