import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import LoginLayout from "containers/login/LoginLayout";
import Email from "../../components/password/Email";
import CreatePassword from "../../components/password/CreatePassword";
import Loading from "../../components/Loading/Loading";
import {
  resetPassword,
  createPassword
} from "../../state/password/passwordAction";

import styleNames from "./passwordProcess.local.css";

const cx = classNames.bind(styleNames);

/**
 * Login Page
 * Specialty: Own Layout component since standard layout cannot and should not be used without user info.
 * Redirects to home, if user is still logged in.
 * */
class PasswordProcess extends Component {
  static propTypes = {
    user: PropTypes.object,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    passwordChange: PropTypes.object
  };

  static getErrorLabel(error) {
    let result = null;

    switch (error) {
      case "invalidEmail":
        result = "Die E-Mail Adresse ist unbekannt.";
        break;
      case "invalidToken":
        result = (
          <span>
            {/* WORKAROUND: Using href because router push isn't working */}
            Der Link zum Einrichten des Passworts ist abgelaufen. Hier kannst du
            dir einen Link zusenden lassen:&nbsp;
            <a href="/resetPassword" className={styleNames.errorLink}>
              Passwort zurücksetzen
            </a>
          </span>
        );
        break;
      default:
        result = null;
        break;
    }

    return result;
  }

  state = {
    step: "email",
    error: "",
    loading: false,
    success: null,
    email: null,
    resetToken: null,
    title: "Passwort einrichten",
    desc:
      "Gib ein sicheres Passwort ein, mit dem du dein Konto schützen möchtest."
  };

  /*
   * No login form if user logged in.
   */
  componentWillMount() {
    const { location, history } = this.props;
    const query = new URLSearchParams(location.search);
    const queryUser = query.get("user");
    const queryToken = query.get("token");

    if (location.pathname === "/setPassword") {
      this.setState({
        title: "Passwort einrichten",
        desc:
          "Gib ein sicheres Passwort ein, mit dem du dein Konto schützen möchtest."
      });
    } else {
      this.setState({
        title: "Neues Passwort einrichten",
        desc:
          "Gib ein neues, sicheres Passwort ein, mit dem du dein Konto schützen möchtest."
      });
    }

    if (this.props.user) {
      history.replace("/");
    } else if (queryUser) {
      this.setState({
        email: queryUser,
        resetToken: queryToken,
        step: "createPassword"
      });
    }
    setTimeout(() => {}, 1000);
  }

  componentWillReceiveProps(nextProps) {
    const { passwordChange } = nextProps;
    const { history } = this.props;

    if (passwordChange) {
      if (passwordChange.result.error) {
        this.setErrorState(
          PasswordProcess.getErrorLabel(passwordChange.result.error)
        );
        return;
      }

      if (passwordChange.loaded && this.state.step === "createPassword") {
        history.replace("/login?message=createPassword");
      } else {
        this.setState({ loading: !passwordChange.loaded });
      }
    }
  }

  setSuccessState(message) {
    this.setState({ success: message, error: null, loading: false });
  }

  setErrorState(message) {
    this.setState({ success: null, error: message, loading: false });
  }

  sendEmail = email => {
    if (email && email.length > 0 && email.indexOf("@") >= 0) {
      this.setSuccessState(
        "Eine Email zum Zurücksetzen des Passworts wurde erfolgreich versendet!"
      );
      this.props.dispatch(resetPassword(email));
    } else {
      this.setErrorState("Die E-Mail Adresse ist nicht korrekt.");
    }
    // this.setState({ error: "Es ist ein Fehler aufgetreten, versuch es bitte später noch einmal." });
    // this.props.dispatch(resetPassword(this.state.email));
  };

  sendPassword = password => {
    const { resetToken, email } = this.state;

    if (password && password.length > 11 && resetToken && email) {
      const send = {
        token: resetToken,
        email,
        password
      };
      this.props.dispatch(createPassword(send));
    } else {
      this.setErrorState(
        "Wähle ein Passwort mit mindestens 12 Zeichen, bestehend aus mehr als 2 Worten, Groß-/Kleinschreibung und Zahlen."
      );
    }
  };

  render() {
    const { step, success, error, loading, title, desc } = this.state;

    return (
      <LoginLayout>
        {step === "email" && <Email sendEmail={this.sendEmail} />}
        {step === "createPassword" && (
          <CreatePassword
            sendPassword={this.sendPassword}
            title={title}
            desc={desc}
          />
        )}
        {loading && <Loading />}
        {success && (
          <div className={`alert alert-success ${cx("infobox")}`}>
            {success}
          </div>
        )}
        {error && (
          <div className={`alert alert-danger ${cx("infobox")}`}>{error}</div>
        )}
      </LoginLayout>
    );
  }
}

function mapStateToProps({ password }) {
  return {
    passwordChange: password
  };
}

export default connect(mapStateToProps)(PasswordProcess);
